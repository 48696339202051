import { EyeIcon, PencilIcon } from '@heroicons/react/24/solid';
import { FunctionComponent } from 'react';
import { AccessRight } from '../../typings/backend-types';

interface Props {
  accessRight: AccessRight;
}

export const AccessRightIcon: FunctionComponent<Props> = ({ accessRight }) => {
  if (accessRight === AccessRight.Read) {
    return readAccessIcon;
  }
  if (accessRight === AccessRight.Write) {
    return writeAccessIcon;
  }
  return (
    <>
      {writeAccessIcon}
      {readAccessIcon}
    </>
  );
};

export const indicatorIconClassNames = 'w-4 h-4 inline align-text-bottom mr-1';
export const readAccessIcon = <EyeIcon className={indicatorIconClassNames} />;
export const writeAccessIcon = (
  <PencilIcon className={indicatorIconClassNames} />
);
