import useSWR from 'swr';
import { HttpEndpoints } from '../../data/httpEndpoints';
import {
  Exam,
  ExamAttempt,
  LicenseCategory,
  WorkEvent,
} from '../../typings/backend-types';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'react-i18next';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { hasPassedTheExam } from '../../components/Exams/ExamHelpers';
import { useAppContext } from '../../context/appContext';
import useUserPermission from '../useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';

export const useNewEventAttempt = (workEvent: WorkEvent) => {
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'exams',
  });
  const { organizationId } = useAppContext();
  const canSeeExams = useUserPermission(Permission.STUDENT_EXAMS, Scope.READ);

  // exam finden
  const { data: exams, isValidating: loading } = useSWR<Exam[]>(
    () =>
      canSeeExams &&
      HttpEndpoints.ExamEndpoints.getExamsForStudent(
        workEvent.student.id,
        organizationId,
        workEvent.billingType.licenseCategory,
      ),
    authenticatedFetcher,
    {
      onError: () => {
        setErrorMessage(t('addView.couldNotFetchExams'));
      },
      fallbackData: [],
    },
  );

  const { data: examAttempts, isValidating: loadingAttempts } = useSWR<
    ExamAttempt[]
  >(
    () =>
      canSeeExams &&
      exams &&
      HttpEndpoints.ExamEndpoints.getExamAttemptsByExam(
        exams.at(0).id,
        workEvent.organization.id,
      ),
    authenticatedFetcher,
    {
      onError: (err) => {
        setErrorMessage(t('addView.couldNotFetchAttempts'));
      },
    },
  );

  const examAttemptIsPossible = useMemo(() => {
    if (!canSeeExams) return false;
    if (loadingAttempts && !examAttempts) {
      return false;
    }
    if (loading && !exams) {
      return false;
    }
    if (!workEvent.billingType?.isExam) return false;
    if (
      workEvent.billingType.licenseCategory == null ||
      workEvent.billingType.licenseCategory == LicenseCategory.None
    )
      return false;

    if (!examAttempts || !exams) return true;

    if (examAttempts.length === 0) {
      return true;
    }

    const moreThanFour = examAttempts.length >= 4;
    const lastAttemptAfterCurrent = examAttempts
      .map((e) => dayjs(e.exam_date))
      .sort((a, b) => a.unix() - b.unix())
      .at(-1)
      .isSameOrAfter(workEvent.start_time);
    const hasPassedExam = hasPassedTheExam(examAttempts);

    return !moreThanFour && !lastAttemptAfterCurrent && !hasPassedExam;
  }, [workEvent, examAttempts, loadingAttempts]);

  return {
    exam: exams?.at?.(0),
    examAttemptIsPossible,
    loading: loading || loadingAttempts,
  };
};
