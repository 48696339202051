import { FunctionComponent } from 'react';
import { Color } from '../../typings/backend-types';
import { classNames } from 'utilities/classNames';

interface Props {
  title: string;
  className?: string;
  icon: (props: { className: string }) => JSX.Element;
  color?: Color;
}

export const InfoBlob: FunctionComponent<Props> = ({
  title,
  icon,
  className,
  children,
  color,
}) => {
  const Icon = icon;
  return (
    <div className="flex flex-row px-4">
      <dt
        className={classNames(
          'p-6 flex items-center justify-center rounded-xl mr-5',
          className,
          `bg-${color ?? 'accent'}-100`,
        )}
      >
        <Icon
          className={classNames('w-7 h-7', `text-${color ?? 'accent'}-400`)}
        />
      </dt>
      <div className="flex flex-col justify-center truncate">
        <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
        <dd className="text-2xl font-semibold text-gray-900">{children}</dd>
      </div>
    </div>
  );
};
