import dayjs from 'dayjs';
import { UpdateLessonRequestDto } from '../../typings/backend-types';
import authenticatedPost from '../../data/authenticatedPost';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { mutate } from 'swr';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import { Notification } from '../../context/notificationContext';

export async function markLessonRequestAsCompleted(
  id: string,
  organizationId: string,
  setErrorMessage: (message: string) => void,
  setNotification?: (notification: Notification) => void,
) {
  try {
    const dto: UpdateLessonRequestDto = {
      completedDate: dayjs().format(),
    };
    await authenticatedPost(
      HttpEndpoints.LessonRequestEndpoints.patch(id, organizationId),
      dto,
      'PATCH',
    );
    void mutate(
      HttpEndpoints.LessonRequestEndpoints.getLessonRequest(id, organizationId),
    );
    setNotification?.({
      title: t('lessonRequests.detailView.markAsCompletedSuccess'),
      icon: <CheckCircleIcon className="w-5 h-5 text-green-500" />,
    });
  } catch (e) {
    void mutate(
      HttpEndpoints.LessonRequestEndpoints.getLessonRequest(id, organizationId),
    );
    setErrorMessage(t('lessonRequests.detailView.markAsCompletedFailed'));
  }
}
