import useSWR from 'swr';
import { useAppContext } from '../context/appContext';
import { LockableAction } from '@tr-types/backend-types';
import { HttpEndpoints } from 'data/httpEndpoints';
import authenticatedFetcher from 'data/authenticatedFetcher';

interface TimePeriod {
  start: string;
  end: string;
}

export default function useLock(
  action: LockableAction,
  time: TimePeriod | string | undefined, // either start and end, or just a single date
): { isLocked: boolean } {
  const { organizationId } = useAppContext();

  const { data: isLocked = false } = useSWR<boolean>(
    () =>
      time &&
      HttpEndpoints.LockPeriods.check(
        organizationId,
        typeof time === 'string' ? time : time.start,
        typeof time === 'string' ? time : time.end,
        action,
      ),
    authenticatedFetcher,
  );

  return { isLocked: isLocked || false };
}
