import {
  User,
  AccessRight,
  Resource,
  AppUsageContract,
  BasicUserInfo,
} from '../../typings/backend-types';
import { hasAccessTo } from './hasAccessTo';

/**
 * Determine if the current user has write access to the specified resource
 * @param resource
 * @param currentUser
 * @param otherUser
 * @returns true if the access right for the matched user is write or readwrite
 */
export function hasWriteAccessTo(
  resource: Resource,
  currentUser: User,
  currentUserTeam: User[],
  otherUser: { id: string },
  orgContract: AppUsageContract | null,
): boolean {
  return (
    hasAccessTo(
      resource,
      AccessRight.Write,
      currentUser,
      currentUserTeam,
      otherUser,
      orgContract,
    ) ||
    hasAccessTo(
      resource,
      AccessRight.ReadWrite,
      currentUser,
      currentUserTeam,
      otherUser,
      orgContract,
    )
  );
}
