import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { classNames } from '../../../utilities/classNames';
import { InfoText } from '../../Common/InfoText';

interface Props {
  label: string;
  formName?: string;
  required?: boolean;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  small?: boolean;
  validity?: string;
  className?: string;
  helpText?: string;
  disabled?: boolean;
}

/**
 * a basic checkbox with the label on the left
 * from the tailwind form layout https://tailwindui.com/components/application-ui/forms/form-layouts#component-cc5bee94bc88387c2511b0e5c3dfffef
 */
export const BasicCheckboxInput: FunctionComponent<Props> = ({
  label,
  formName,
  required,
  defaultChecked,
  small,
  onChange,
  validity,
  className,
  helpText,
  disabled,
}) => {
  const [checked, setChecked] = useState(!!defaultChecked);
  useEffect(() => {
    setChecked(!!defaultChecked);
  }, [defaultChecked]);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e);
    setChecked(e.target.checked);
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.setCustomValidity(validity ?? '');
      ref.current.reportValidity();
    }
  }, [validity]);

  return (
    <div className={className}>
      <div
        className={classNames(
          small
            ? 'relative flex items-start mt-2'
            : 'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:border-t sm:pt-5',
        )}
      >
        <label
          htmlFor={formName}
          className={classNames(
            'text-sm font-medium text-gray-700 ',
            small ? 'order-2 ml-3' : 'block sm:mt-px',
          )}
        >
          {label} {required && '*'}
        </label>
        <div
          className={classNames(
            small ? 'flex items-center h-5' : 'mt-1 sm:mt-0 sm:col-span-2',
          )}
        >
          <input
            id={formName}
            name={formName}
            disabled={disabled}
            required={required}
            checked={checked ?? false}
            ref={ref}
            onChange={onInputChange}
            type="checkbox"
            className={classNames(
              'focus:ring-accent-500 h-4 w-4 border-gray-300 rounded',
              disabled ? 'text-gray-400' : 'text-accent-600',
            )}
          />
          {helpText && <InfoText className="mt-2">{helpText}</InfoText>}
        </div>
      </div>
    </div>
  );
};
