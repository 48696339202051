import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Color, WorkEvent } from '../../../../../typings/backend-types';
import {
  EventDescriptionItem,
  EventDescriptionLink,
} from '../EventDescriptionItem';
import { Tag } from '../../../../Common/Tag';
import { Routes } from '../../../../../utilities/routes';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../../typings/roleConfig';
import { translateLicenseCategory } from '../../../../../utilities/translation/translate-license-category';

export const ExamAttemptDetail: FunctionComponent<{
  event: WorkEvent;
}> = ({ event }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewWorkEvent.examAttemptInfo',
  });

  const canSeeExams = useUserPermission(Permission.STUDENT_EXAMS, Scope.READ);

  if (!canSeeExams) return null;

  return (
    <div className="divide-y">
      <div className="grid grid-cols-2 gap-4">
        <EventDescriptionItem label={t('exam')}>
          <EventDescriptionLink
            href={Routes.Students.Exams.DetailView(
              event.student.id,
              event.examAttempt.exam.id,
              event.id,
            )}
            showLink
          >
            {translateLicenseCategory(event.examAttempt.exam.licenseCategory)}
          </EventDescriptionLink>
        </EventDescriptionItem>
        <EventDescriptionItem label={t('attemptNumber')}>
          {event.examAttempt.attempt_number}
        </EventDescriptionItem>
      </div>
      <EventDescriptionItem label={t('examiner')}>
        {event.examAttempt.examiner}
      </EventDescriptionItem>

      <EventDescriptionItem label={t('comment')}>
        {event.examAttempt.comment}
      </EventDescriptionItem>
      <EventDescriptionItem label="">
        <div className="text-sm">
          {event.examAttempt.passed ? (
            <Tag color={Color.Green}>{t('passed')}</Tag>
          ) : (
            <Tag color={Color.Red}>{t('failed')}</Tag>
          )}
        </div>
      </EventDescriptionItem>
    </div>
  );
};
