import { FormEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { BasicTextInput } from '../../../../Forms/FormFields/BasicTextInput';
import {
  CreateExamDto,
  UpdateExamAttemptDto,
  UpdateWorkEventDto,
  WorkEvent,
} from '../../../../../typings/backend-types';
import { useErrorPopupContext } from '../../../../../context/errorPopupContext';
import { HttpEndpoints } from '../../../../../data/httpEndpoints';
import authenticatedPost from '../../../../../data/authenticatedPost';
import {
  examAttemptRadioRenderer,
  getNextAttemptNumber,
  examAttemptStatusOptions,
} from '../../../../Exams/ExamHelpers';
import { Form } from '../../../../Forms/Form';
import { RadioList } from '../../../../Forms/FormFields/RadioList';
import { useNewEventAttempt } from '../../../../../hooks/Exams/useNewEventAttempt';
import { ExaminerInput } from '../../../../Exams/ExaminerInput';
import { suggestArchivingPassedStudent } from '../../../../../utilities/students/suggestArchivingPassedStudent';
import { useConfirmPopupContext } from '../../../../../context/confirmPopupContext';
import { useNotificationContext } from '../../../../../context/notificationContext';
import { useAppContext } from '../../../../../context/appContext';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../../typings/roleConfig';
import { WorkEventPopupTab } from './WorkEventViewPopup';

interface Props {
  changeTab: (tab: WorkEventPopupTab) => void;
  workEvent: WorkEvent;
}

interface FormElements {
  status: HTMLFormElement;
  comment: HTMLFormElement;
}

export const ExamAttemptCreateForm: FunctionComponent<Props> = ({
  changeTab,
  workEvent,
}) => {
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'exams',
  });
  const { confirm } = useConfirmPopupContext();
  const { setNotification } = useNotificationContext();
  const { organizationId } = useAppContext();

  const [examiner, setExaminer] = useState('');

  const { exam, examAttemptIsPossible, loading } =
    useNewEventAttempt(workEvent);

  async function submit(event: FormEvent<FormElements>): Promise<void> {
    event.preventDefault();
    const target = event.currentTarget;
    const comment = target.comment.value;
    const passed = target.status.value === 'passed' ? true : false;

    let examId: string;
    if (!exam) {
      // create an exam if there is none
      const examdto: CreateExamDto = {
        licenseCategory: workEvent.billingType.licenseCategory,
        student: { id: workEvent.student.id },
      };

      const res = await authenticatedPost(
        HttpEndpoints.ExamEndpoints.postExam(workEvent.organization.id),
        examdto,
      );

      examId = (await res.json()).id;
    } else {
      examId = exam.id;
    }

    const examAttemptNo = exam?.examAttempts
      ? getNextAttemptNumber(exam.examAttempts)
      : 1;

    const examAttemptDto: UpdateExamAttemptDto = {
      exam: { id: examId },
      passed,
      attempt_number: examAttemptNo,
      examiner,
      comment,
    };

    // post the thing!
    const createdAttemptRes = await authenticatedPost(
      HttpEndpoints.ExamAttemptEndpoints.postExamAttempt(
        workEvent.organization.id,
      ),
      examAttemptDto,
    );

    if (passed) {
      void suggestArchivingPassedStudent(
        workEvent.student,
        organizationId,
        confirm,
        setErrorMessage,
        setNotification,
      );
    }

    // update work event
    const workEventDto: UpdateWorkEventDto = {
      examAttempt: { id: (await createdAttemptRes.json()).id },
    };

    await authenticatedPost(
      HttpEndpoints.WorkEventEndpoints.patchWorkEvent(
        workEvent.id,
        workEvent.user?.id,
      ),
      workEventDto,
      'PATCH',
    );

    await mutate(
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        workEvent.id,
        workEvent.user?.id,
      ),
    );
    changeTab(WorkEventPopupTab.examAttemptDetails);
  }

  const canEditExams = useUserPermission(
    Permission.STUDENT_EXAMS,
    Scope.READ_WRITE,
  );

  if (!canEditExams) return null;

  return (
    <Form<FormElements>
      onSubmit={submit}
      saveDisabled={!examAttemptIsPossible || loading}
      onCancel={() => changeTab(WorkEventPopupTab.eventDetails)}
      onSubmitError={() => {
        setErrorMessage(t('addView.couldNotAddMessage'));
      }}
    >
      <div className="space-y-8 divide-gray-200 sm:space-y-5">
        <ExaminerInput onChange={setExaminer} />
        <RadioList
          formName="status"
          label={t('status')}
          options={examAttemptStatusOptions}
          defaultCheckedId={null}
          renderOption={examAttemptRadioRenderer}
          required
          small
          inlineOptions
        />
        <BasicTextInput
          small
          placeholder={t('attemptForm.commentPlaceholder')}
          label={t('comment')}
          formName="comment"
          type="text"
        />
      </div>
    </Form>
  );
};
