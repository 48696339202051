import { Address } from '../typings/backend-types';
import { formatAddress } from './formatAddress';

/**
 * Constructs a URL that leads to Google Maps navigation to the gicen address, starting at the most relevant starting location according to Google Maps (usually current location).
 * The URL is constructed according to the documentation here: https://developers.google.com/maps/documentation/urls/get-started
 */
export function gmapsNavigationLink(destination: Address): string {
  return (
    'https://www.google.com/maps/dir/?api=1&destination=' +
    encodeURIComponent(formatAddress(destination))
  );
}
