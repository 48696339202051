import { EventInput } from '@fullcalendar/react';
import dayjs from 'dayjs';
import { range } from 'lodash';
import { FunctionComponent } from 'react';
import isoWeek from 'dayjs/plugin/isoWeek';
import { classNames } from '../../utilities/classNames';
dayjs.extend(isoWeek);

interface Props {
  calendarEventInputs: EventInput[];
  month: number;
  year: number;
  onDayClicked: (day: number, month: number, year: number) => void;
}

export const MobileMonthCalendar: FunctionComponent<Props> = ({
  calendarEventInputs,
  month,
  year,
  onDayClicked,
}) => {
  if (year == null || month == null) return null;
  const firstWeekDay =
    dayjs().year(year).month(month).startOf('month').isoWeekday() - 1;
  const daysOfMonth = dayjs()
    .year(year)
    .month(month + 1)
    .date(1)
    .subtract(1, 'day')
    .date();

  function isOnDate(e: EventInput, day: number, month: number, year: number) {
    if (e?.extendedProps?.isHoliday) return false;
    const date = dayjs(e.start as string | number | Date);
    return (
      date.date() === day && date.month() === month && date.year() === year
    );
  }

  return (
    <div key={month} className="pb-1 select-none">
      <div className="grid grid-cols-7 font-normal mt-3 gap-x-3 gap-y-6 justify-items-center">
        {/* header with weekday abbreviations */}
        {range(0, 7).map((w, index) => (
          <p className="font-normal text-sm text-center" key={index}>
            {dayjs().startOf('isoWeek').add(w, 'day').format('dd')}
          </p>
        ))}
        {/* filler to start at right weekday */}
        {firstWeekDay !== 0 && (
          <span className={'col-span-' + firstWeekDay}></span>
        )}
        {/* map over each day of the month */}
        {range(0, daysOfMonth).map((day) => (
          <span
            key={day}
            className={classNames(
              'h-6 w-6 leading-6 cursor-pointer rounded-full text-sm text-center',
            )}
            onClick={() => onDayClicked(day + 1, month, year)}
          >
            {day + 1}
            {calendarEventInputs.find((e) =>
              isOnDate(e, day + 1, month, year),
            ) && (
              <div className="bg-accent-400 rounded-full w-1.5 h-1.5 mx-auto"></div>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};
