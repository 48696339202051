import React, { FunctionComponent, SVGProps } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { popupPositionStyle } from '../../helpers/CalendarHelpers';
import { AuthorizedPageWrapper } from '@components/PageContentWrapper/AuthorizedPageWrapper';
import { SlideOverPanel } from '@components/Common/SlideOverPanel';
import useWindowDimensions, { Breakpoints } from 'hooks/useWindowDimensions';
import 'animate.css';

interface AdditionalOption {
  action: () => void;
  altText?: string;
  icon: JSX.Element;
}

interface Props {
  position: [number, number];
  isOpen: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  additionalActions?: AdditionalOption[];
}

export const CalendarPopupWrapper: FunctionComponent<Props> = ({
  position,
  isOpen,
  onClose,
  title,
  children,
  icon,
  additionalActions,
}) => {
  const Icon = icon;

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.common',
  });

  const isSmallScreen = useWindowDimensions().width < Breakpoints.LG;

  if (!isOpen || !position) return null;

  return (
    <AuthorizedPageWrapper disableSidebar authorizationRequirements={null}>
      {isSmallScreen ? (
        <SlideOverPanel
          open={isOpen}
          onClose={onClose}
          icon={Icon}
          title={title}
        >
          {additionalActions?.length > 0 && (
            <div className={'flex flex-row w-full gap-3 mb-4'}>
              {additionalActions?.map((o, i) => (
                <button
                  key={i}
                  type="button"
                  className="flex flex-grow items-center rounded-md px-2 py-4 bg-accent-100 font-medium text-accent-600 hover:text-gray-600 focus:outline-none "
                  onClick={o.action}
                >
                  <div className="inline-block">{o.icon}</div>
                  {o.altText}
                </button>
              ))}
            </div>
          )}
          <div>{children}</div>
        </SlideOverPanel>
      ) : (
        <div
          className="fixed z-50 bg-white h-auto rounded-lg shadow-xl animate__animated animate__zoomIn animate__faster"
          style={popupPositionStyle(position)}
          data-cy="calendar-popup"
        >
          <div className="bg-accent-50 py-8 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <p className="text-lg font-medium text-accent-600 flex items-center">
                {Icon && (
                  <Icon
                    className="h-6 w-6 inline-block mr-2 text-accent-600 shrink-0"
                    aria-hidden="true"
                  />
                )}
                {title}
              </p>
              <div className="ml-2 flex h-7 items-center">
                {additionalActions?.map((o, i) => (
                  <button
                    key={i}
                    type="button"
                    className="rounded-md bg-accent-50 text-accent-600 hover:text-gray-600 focus:outline-none "
                    onClick={o.action}
                  >
                    <span className="sr-only">{o.altText}</span>
                    {o.icon}
                  </button>
                ))}
                <button
                  type="button"
                  className="rounded-md bg-accent-50 text-accent-600 hover:text-gray-600 focus:outline-none "
                  onClick={onClose}
                >
                  <span className="sr-only">{t('closePopup')}</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="p-5">{children}</div>
        </div>
      )}
    </AuthorizedPageWrapper>
  );
};
