import { Color } from '../../../typings/backend-types';

interface ColorInfo {
  text: string;
  bg: string;
}

export const EventColors: Record<Color, ColorInfo> = {
  red: {
    text: '#DC2626', // Tailwind red
    bg: '#FEE2E2',
  },
  orange: {
    text: '#EA580C', // Tailwind Orange
    bg: '#FFEDD5',
  },
  yellow: {
    text: '#CA8A04', // Tailwind Yellow
    bg: '#FEF9C3',
  },
  emerald: {
    text: '#059669', // Tailwind Emerald
    bg: '#D1FAE5',
  },
  green: {
    text: '#16A34A', // Tailwind Green
    bg: '#DCFCE7',
  },
  teal: {
    text: '#0D9488', // Tailwind Teal
    bg: '#CCFBF1',
  },
  cyan: {
    text: '#0891B2', // Tailwind Cyan
    bg: '#CFFAFE',
  },
  sky: {
    text: '#0284C7',
    bg: '#E0F2FE', // Tailwind Sky
  },
  blue: {
    text: '#2563EB', // Tailwind Blue
    bg: '#DBEAFE',
  },
  violet: {
    text: '#7C3AED', // Tailwind Violet
    bg: '#EDE9FE',
  },
  pink: {
    text: '#DB2777', // Tailwind Pink
    bg: '#FCE7F3',
  },
  rose: {
    text: '#E11D48',
    bg: '#FFE4E6', // Tailwind Rose
  },
  slate: {
    text: '#475569', // Tailwind Slate
    bg: '#F1F5F9',
  },
  amber: {
    text: '#D97706', // Tailwind Amber
    bg: '#FEF3C7',
  },
  lime: {
    text: '#65A30D', // Tailwind Lime
    bg: '#ECFCCB',
  },
  indigo: {
    text: '#4F46E5', // Tailwind indigo
    bg: '#E0E7FF',
  },
  purple: {
    text: '#9333EA', // Tailwind indigo
    bg: '#F3E8FF',
  },
  fuchsia: {
    text: '#C026D3', // Tailwind fuchsia
    bg: '#FAE8FF',
  },
  gray: {
    text: '#4B5563', // Tailwind gray
    bg: '#F3F4F6',
  },
  zinc: {
    text: '#52525B', // Tailwind zinc
    bg: '#F4F4F5',
  },
  neutral: {
    text: '#525252', // Tailwind neutral
    bg: '#F5F5F5',
  },
  stone: {
    text: '#57534e',
    bg: '#f5f5f4',
  },
};
