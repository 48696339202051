import { TrashIcon, CheckIcon } from '@heroicons/react/24/outline';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Common/Buttons/Button';
import { DeleteConfirmationPopup } from '@components/InfoScreens/DeleteConfirmationPopup';

interface Props {
  onDelete: () => void;
  onClose: () => void;
  saveDisabled?: boolean;
  submitLoading?: boolean;
}

export const BottomButtonRow: FunctionComponent<Props> = ({
  onDelete,
  onClose,
  saveDisabled,
  submitLoading,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.common',
  });

  const [deleteConfirmPopupOpen, setDeleteConfirmPopupOpen] = useState(false);

  return (
    <>
      <div>
        <div className="flex justify-end">
          <Button onClick={onClose} className="mr-3">
            {t('cancel')}
          </Button>
          {onDelete && (
            <Button
              icon={TrashIcon}
              color="red"
              onClick={() => setDeleteConfirmPopupOpen(true)}
              className="mr-3"
            >
              {t('delete')}
            </Button>
          )}
          <Button
            icon={CheckIcon}
            type="submit"
            color="primary"
            id="calendar-popup-save-button"
            loading={submitLoading}
            disabled={saveDisabled}
          >
            {t('save')}
          </Button>
        </div>
      </div>
      <DeleteConfirmationPopup
        open={deleteConfirmPopupOpen}
        message={t('deleteConfirmation')}
        onOk={onDelete}
        onClose={() => setDeleteConfirmPopupOpen(false)}
      />
    </>
  );
};
