import { isValidElement, useEffect, useState } from 'react';
import { classNames } from 'utilities/classNames';

interface TabListProps<T> {
  children: React.ReactElement<TabProps<T>>[];
  onChange?: (tabId: T) => void;
  defaultSelected?: T;
  className?: string;
}

/**
 * Tabs navigation component based on the tailwind component https://tailwindui.com/components/application-ui/navigation/tabs#component-556317db33900f6182a5f45cd9b50300
 */
export function TabList<T extends string>({
  children,
  onChange,
  defaultSelected,
  className,
}: TabListProps<T>): JSX.Element {
  const [currentTab, setCurrentTab] = useState<T | undefined>(defaultSelected);

  useEffect(() => {
    if (defaultSelected) {
      setCurrentTab(defaultSelected);
    }
  }, [defaultSelected]);

  function selectTab(id: T) {
    setCurrentTab(id);
    onChange?.(id);
  }

  // Filter out null tabs
  children = children.filter(Boolean);

  return (
    <>
      <div className={classNames(className ? className : 'mb-2')}>
        {/* Select menu used for small viewports */}
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-accent-500 focus:border-accent-500 border-gray-300 rounded-lg text-sm"
            value={currentTab}
            onChange={(e) => {
              selectTab(e.target.value as T);
            }}
          >
            {children?.map((tab) => (
              <option key={tab.props.id} value={tab.props.id}>
                {tab.props.title}
              </option>
            ))}
          </select>
        </div>
        {/* Tab pills */}
        <div className="hidden sm:block">
          <nav className="flex space-x-2" aria-label="Tabs">
            {children &&
              children.map((tab) => (
                <button
                  key={tab.props.id}
                  onClick={() => selectTab(tab.props.id)}
                  className={classNames(
                    tab.props.id === currentTab
                      ? 'bg-accent-100 text-accent-600'
                      : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 font-medium text-sm rounded-lg',
                  )}
                  aria-current={
                    tab.props.id === currentTab ? 'page' : undefined
                  }
                >
                  {tab.props.title}
                  {!!tab.props.badge && (
                    <span
                      className={classNames(
                        tab.props.id !== currentTab
                          ? 'bg-accent-100 text-accent-600'
                          : 'bg-accent-700 text-accent-100',
                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
                      )}
                    >
                      {tab.props.badge}
                    </span>
                  )}
                </button>
              ))}
          </nav>
        </div>
      </div>
      {children && children.find((c) => c.props.id === currentTab)}
    </>
  );
}

interface TabSelectorProps<T> {
  children: React.ReactElement<TabProps<T>>[] | React.ReactElement<TabProps<T>>;
  showTabWithId: T;
}

/**
 * @deprecated See thread on Github https://github.com/dot9-software/transportschule-webapp/pull/571#discussion_r982116646
 */
export function TabSelector<T>({
  children,
  showTabWithId,
}: TabSelectorProps<T>): JSX.Element {
  const childArray = Array.isArray(children) ? children : [children];
  return (
    <>
      {childArray?.map((tab) => {
        if (isValidElement(tab) && tab.props.id === showTabWithId) {
          return tab.props.children;
        }
      })}
    </>
  );
}

interface TabProps<T> {
  id: T;
  title?: string | null;
  badge?: string | number;
  children?: React.ReactNode;
}

export function Tab<T>({ children }: TabProps<T>): JSX.Element {
  return <>{children}</>;
}
