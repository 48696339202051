import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { EventDropArg } from '@fullcalendar/react';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { ConfirmPopupInfo } from '../../../context/confirmPopupContext';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import {
  AppUsageContract,
  Resource,
  User,
  VacationEvent,
  Vehicle,
  WorkEvent,
} from '../../../typings/backend-types';
import { hasReadWriteAccessTo } from '../../../utilities/access-rights/hasReadWriteAccessTo';
import { isTouchDevice } from '../../../utilities/isTouchDevice';
import { canEditInTime } from './CalendarAccessRightsHelpers';
import {
  checkForOverlappingVacations,
  isFullDayEvent,
  validateVacation,
} from './CalendarHelpers';

/**
 * Checks whether moving an event is valid and intended. It checks the following criteria:
 * - for touchscreen: confirm this action is intended
 * - check whether user can edit in this time
 * - check whether user can edit this specific calendar
 * - check whether user is trying to convert between vacation and work event
 * - check whether vacation is moved to overlapping vacation
 * - confirm if vacation is moved to weekend or holiday
 * - check whether vehicles are still available
 * @returns a string optionally containing an error message if validation failed, or null if validation was successful
 */
export async function validateMoveOrCopyEvent(
  event: EventDropArg | EventResizeDoneArg,
  calendarEntry: WorkEvent | VacationEvent,
  confirm: (i: ConfirmPopupInfo) => Promise<void> | void,
  loggedInUser: User,
  loggedInUserTeam: User[],
  mode: 'move' | 'copy',
  orgContract: AppUsageContract | null,
): Promise<string | null> {
  const translate = (key: string, opts?: unknown) =>
    t(`calendarPopups.validation.${key}`, opts);

  // confirm moving the event is intended on touchscreens
  if (isTouchDevice()) {
    try {
      let confirmMessage: string;
      if (event.event.allDay) {
        confirmMessage = translate('confirmMoveVacationStart', {
          date: dayjs(event.event.start).format(translate('dateFormat')),
        });
      } else {
        confirmMessage = translate('confirmMoveEvent', {
          date: dayjs(event.event.start).format(translate('dateFormat')),
        });
      }
      await confirm({
        title: translate('confirmDragTitle'),
        message: confirmMessage,
      });
    } catch (err) {
      return '';
    }
  }

  if (event.event.end < event.event.start) {
    return translate('errorStartTimeBeforeEndTime');
  }

  // check whether user can edit in destination time w.r.t. locked periods and non-admins not being allowed to edit in the past
  if (
    !(await canEditInTime(
      {
        start: dayjs(event.event.start),
        end: dayjs(event.event.end),
      },
      loggedInUser,
    ))
  ) {
    return translate('errorCannotEditThisDay');
  }

  // check whether user can edit in original event time if event is being moved
  if (
    mode === 'move' &&
    (!(await canEditInTime(
      {
        start: dayjs(calendarEntry.start_time),
        end: dayjs(calendarEntry.end_time),
      },
      loggedInUser,
    )) ||
      !calendarEntry)
  ) {
    return translate('errorCannotEditThisDay');
  }

  // check whether the user can actually edit this calendar
  if (
    !hasReadWriteAccessTo(
      Resource.Calendar,
      loggedInUser,
      loggedInUserTeam,
      calendarEntry.user,
      orgContract,
    )
  ) {
    return translate('errorCannotEditUsersCalendar', calendarEntry.user);
  }

  // check if event is vacation event
  const isAllDay = isFullDayEvent(
    calendarEntry.start_time,
    calendarEntry.end_time,
  );
  // check whether the user is trying to convert between a vacation event and a work event
  if (isAllDay && !event.event.allDay) {
    return translate('errorConvertWorkToVacation');
  } else if (!isAllDay && event.event.allDay) {
    return translate('errorConvertVacationToWork');
  }

  // check whether there is overlapping vacation events
  if (isAllDay && event.event.allDay) {
    const vacationStart: Dayjs = dayjs(event.event.start).startOf('day');
    const vacationEnd: Dayjs = dayjs(event.event.end).startOf('day');

    const doVacationsOverlap = await checkForOverlappingVacations(
      calendarEntry.user.id,
      calendarEntry,
      {
        start: vacationStart,
        end: vacationEnd,
      },
    );

    if (doVacationsOverlap) {
      return translate('errorOverlappingVacation');
    }

    // confirm in case the vacation is being moved to a holiday or weekend
    if (
      !(await validateVacation(
        loggedInUser.organization.id,
        vacationStart,
        vacationEnd,
        confirm,
      ))
    ) {
      return '';
    }
  }

  // check whether vehicles are available in desired time
  if (
    'vehicle' in calendarEntry &&
    calendarEntry.vehicle &&
    !(await isSelectedVehicleAvailable(
      event,
      calendarEntry.vehicle,
      loggedInUser.organization!.id,
    ))
  ) {
    return translate('errorVehicleUnavailable', {
      vehicle:
        calendarEntry.vehicle.title || calendarEntry.vehicle.license_plate,
    });
  }
  if (
    'secondaryVehicle' in calendarEntry &&
    calendarEntry.secondaryVehicle &&
    !(await isSelectedVehicleAvailable(
      event,
      calendarEntry.secondaryVehicle,
      loggedInUser.organization!.id,
    ))
  ) {
    return translate('errorSecondVehicleUnavailable', {
      vehicle:
        calendarEntry.secondaryVehicle.title ||
        calendarEntry.secondaryVehicle.license_plate,
    });
  }

  return null;
}

async function isSelectedVehicleAvailable(
  event: EventDropArg | EventResizeDoneArg,
  vehicle: Vehicle,
  organizationId: string,
) {
  if (vehicle.id == null) return true;

  const res: WorkEvent[] = (
    await authenticatedFetcher(
      HttpEndpoints.WorkEventEndpoints.getWorkEventsByVehicle(
        vehicle.id,
        organizationId,
        {
          timespanBegin: dayjs(event.event.start).toISOString(),
          timespanEnd: dayjs(event.event.end).toISOString(),
        },
      ),
    )
  ).filter((e: WorkEvent) => e.id !== event.event.id);
  return res.length === 0;
}
