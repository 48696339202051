import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { FunctionComponent } from 'react';

interface DescriptionProps {
  label: string;
}

export const EventDescriptionItem: FunctionComponent<DescriptionProps> = ({
  label,
  children,
}) => {
  return (
    <div className="py-3">
      <div className="text-xs text-gray-500 font-normal">{label}</div>
      <span className="font-normal text-base">{children}</span>
    </div>
  );
};

interface EventDescriptionLinkProps {
  href: string;
  showLink: boolean;
}

export const EventDescriptionLink: FunctionComponent<
  EventDescriptionLinkProps
> = ({ href, children, showLink }) => {
  if (showLink) {
    return (
      <Link href={href}>
        <a className="hover:text-accent-600">
          <span className="align-middle">{children}</span>
          <ChevronRightIcon className="w-4 h-4 stroke-2 inline-block align-middle ml-1" />
        </a>
      </Link>
    );
  }

  return <div>{children}</div>;
};
