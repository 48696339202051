import React, { FunctionComponent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { CalendarPopupWrapper } from '../Common/CalendarPopupWrapper';
import { CalendarSelection } from '../Common/CalendarSelection';
import { EventDescriptionItem } from './EventDescriptionItem';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';
import { displayDateFormat } from 'utilities/dateFormat';

interface Props {
  position: [number, number];
  isOpen: boolean;
  onClose: () => void;
  calendarSelection: CalendarSelection;
}

export const IcalEventViewPopup: FunctionComponent<Props> = ({
  position,
  isOpen,
  onClose,
  calendarSelection,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewIcalEvent',
  });

  const startDate: Dayjs = dayjs(calendarSelection.start.toString());
  const endDate: Dayjs = dayjs(calendarSelection.end.toString());

  function getEventPopupHeaderText(startDate: Dayjs, endDate: Dayjs): string {
    if (
      startDate.format(displayDateFormat) === endDate.format(displayDateFormat)
    ) {
      return t('titleWithDate', {
        on: startDate.format(displayDateFormat),
        from: startDate.format('HH:mm'),
        to: endDate.format('HH:mm'),
      });
    }
    const dayBeforeEnd: Dayjs = endDate.subtract(1, 'day');
    return t('titleNoDate', {
      from: startDate.format(displayDateFormat),
      to: dayBeforeEnd.format(displayDateFormat),
    });
  }

  return (
    <CalendarPopupWrapper
      position={position}
      isOpen={isOpen}
      icon={CalendarIcon}
      onClose={onClose}
      title={getEventPopupHeaderText(startDate, endDate)}
    >
      {calendarSelection ? (
        <EventDetails calendarSelection={calendarSelection} />
      ) : (
        <LoadingIndicator />
      )}
    </CalendarPopupWrapper>
  );
};

const EventDetails: FunctionComponent<{
  calendarSelection: CalendarSelection;
}> = ({ calendarSelection }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewIcalEvent',
  });
  return (
    <div className="divide-y">
      {calendarSelection.eventTitle && (
        <EventDescriptionItem label={t('titleLabel')}>
          <div className="text-l">{calendarSelection.eventTitle}</div>
        </EventDescriptionItem>
      )}
      {calendarSelection.eventDescription && (
        <EventDescriptionItem label={t('descriptionLabel')}>
          <div className="text-l">{calendarSelection.eventDescription}</div>
        </EventDescriptionItem>
      )}
    </div>
  );
};
