import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, MeetingPoint } from '../../../typings/backend-types';
import { BasicSelectInput } from '../../Forms/FormFields/BasicSelectInput';
import { DynamicAddressAutocompleteField } from '../../Forms/FormFields/DynamicAddressAutocompleteField';

interface Props {
  specifyAddress: boolean;
  point: MeetingPoint;
  points: MeetingPoint[];
  onPointChange: (mp: MeetingPoint) => void;
  address: Address;
  onAddressChange: (a: Address) => void;
}

export const MeetingLocationInput: FunctionComponent<Props> = ({
  specifyAddress,
  points,
  point,
  onPointChange,
  address,
  onAddressChange,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.common',
  });

  if (!specifyAddress) {
    return (
      <BasicSelectInput
        options={points}
        label={t('meetingPointLabel')}
        formName="meetingPoint"
        value={point?.id}
        bindValue={(mp: MeetingPoint) => mp.id}
        bindLabel={(mp: MeetingPoint) => mp.title}
        onChange={(e) =>
          onPointChange(
            points.find((mp: MeetingPoint) => mp.id === e.target.value),
          )
        }
        small
      />
    );
  }

  return (
    <DynamicAddressAutocompleteField
      label={t('meetingAddressLabel')}
      value={address}
      formName="meetingAddress"
      onChange={onAddressChange}
      small
    />
  );
};
