import { Dispatch, SetStateAction } from 'react';

/**
 * Converts a setState function of an object with multiple props
 * into a setState function that only updates the specified props.
 * @param {Dispatch<SetStateAction<T>>} setState
 * @param {SetStateAction<Partial<T>>} action
 * @returns {void}
 */
export function partialSetState<T>(
  setState: Dispatch<SetStateAction<T>>,
  action: SetStateAction<Partial<T>>,
): void {
  setState((oldValues) => {
    if (typeof action === 'function') {
      return { ...oldValues, ...action(oldValues) };
    } else return { ...oldValues, ...action };
  });
}
