import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { ComboboxInput } from '../../Forms/FormFields/ComboboxInput';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { timeStringToNumber } from '../helpers/CalendarHelpers';
import { formatName } from '../../../utilities/formatName';
import {
  licenseCategories,
  translateLicenseCategory,
} from '../../../utilities/translation/translate-license-category';
import useUserPermission from '../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../typings/roleConfig';
import { MeetingLocationInput } from './MeetingLocationInput';
import { ErrorText } from '@components/Common/ErrorText';
import { BasicSelectInput } from '@components/Forms/FormFields/BasicSelectInput';
import { StudentFormFields } from '@components/Forms/students/StudentFormFields';
import {
  BillingType,
  Student,
  Vehicle,
  MeetingPoint,
  Address,
  LicenseCategory,
  User,
  StudentGroup,
  LessonRequest,
  Color,
} from '@tr-types/backend-types';
import { useAppContext } from 'context/appContext';
import { BasicCheckboxInput } from '@components/Forms/FormFields/BasicCheckboxInput';
import {
  BasicTextInput,
  dateInputFormat,
} from '@components/Forms/FormFields/BasicTextInput';
import { partialSetState } from 'utilities/partialSetState';
import { Tag } from '@components/Common/Tag';
import { indicatorIconClassNames } from '@components/AccessRights/AccessRightIcon';

export interface WorkEventFormState {
  startTime: string;
  endTime: string;
  userId: string;
  studentId: string;
  billingType: BillingType;
  primaryVehicleId: string;
  secondaryVehicleId: string;
  meetingPoint: MeetingPoint;
  meetingAddress: Address;
  addNewStudent: boolean;
  studentDisabled?: boolean;
  specifyMeetingAddress: boolean;
  date: string;
  licenseCategory: string;
  studentGroups: StudentGroup[];
  newStudent?: Partial<Student>;
}

interface Props {
  workEventFormState: WorkEventFormState;
  setWorkEventFormState: Dispatch<SetStateAction<WorkEventFormState>>;
  vehicles: Vehicle[];
  lessonRequest?: LessonRequest;
  // whether the form is currently being used in an update instead of create context
  isUpdate: boolean;
}

export const WorkEventForm: FunctionComponent<Props> = ({
  setWorkEventFormState,
  lessonRequest,
  workEventFormState,
  vehicles,
  isUpdate,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.createUpdateWorkEvent.form',
  });

  const { organizationId } = useAppContext();
  const canEditAllCalendars = useUserPermission(
    Permission.ALL_CALENDARS,
    Scope.READ_WRITE,
  );
  const canAssignInstructor = useUserPermission(
    Permission.STUDENT_ASSIGN_INSTRUCTOR,
    Scope.READ_WRITE,
  );
  const canEditPastEvents = useUserPermission(
    Permission.EDIT_PAST_EVENTS,
    Scope.READ_WRITE,
  );
  const canCreateOwnStudent = useUserPermission(
    Permission.OWN_STUDENTS_INFO,
    Scope.READ_WRITE,
  );
  const canCreateAllStudents = useUserPermission(
    Permission.ALL_STUDENTS_INFO,
    Scope.READ_WRITE,
  );

  const { data: meetingPoints } = useSWR<MeetingPoint[]>(
    () =>
      HttpEndpoints.MeetingPointEndpoints.getMeetingPointsByOrganization(
        organizationId,
      ),
    authenticatedFetcher,
  );

  const setFields = (action: SetStateAction<Partial<WorkEventFormState>>) =>
    partialSetState(setWorkEventFormState, action);

  async function selectBillingType(billingType?: BillingType): Promise<void> {
    setFields({ billingType });
    if (!billingType || billingType.id === workEventFormState.billingType?.id) {
      return;
    }
    if (billingType.duration_minutes && workEventFormState.startTime) {
      setFields({
        endTime: dayjs(workEventFormState.startTime, 'h:mm')
          .add(billingType.duration_minutes, 'minutes')
          .format('HH:mm'),
      });
    }
  }

  // set startTime and update endTime with correct distance
  function setSelectedStartTime(newStart: string): void {
    setFields({
      startTime: newStart,
    });
    if (!workEventFormState.endTime || !newStart) return;
    const duration =
      timeStringToNumber(workEventFormState.endTime) -
      timeStringToNumber(workEventFormState.startTime);
    const newEnd = Math.min(23.99, timeStringToNumber(newStart) + duration);
    setFields({
      endTime: dayjs().startOf('day').add(newEnd, 'hours').format('HH:mm'),
    });
  }

  const vehicleRequired =
    workEventFormState.billingType?.licenseCategory !== LicenseCategory.None;

  return (
    <>
      <BasicTextInput
        type="date"
        onChange={(e) => setFields({ date: e.target.value })}
        label={t('dateLabel')}
        required
        formName="date"
        helpText={
          lessonRequest?.requestedTimeframes &&
          t('requestedTimeframes', {
            requestedTimeframes: lessonRequest.requestedTimeframes.join(', '),
          })
        }
        small
        value={workEventFormState.date}
        min={canEditPastEvents ? undefined : dayjs().format(dateInputFormat)}
      />
      <div>
        <div className="inline-block w-1/2 pr-3">
          <BasicTextInput
            type="time"
            label={t('fromLabel')}
            required
            small
            onChange={(e) => setSelectedStartTime(e.target.value)}
            value={workEventFormState.startTime}
            formName="from"
          />
        </div>

        <div className="inline-block w-1/2">
          <BasicTextInput
            type="time"
            onChange={(e) => setFields({ endTime: e.target.value })}
            label={t('toLabel')}
            required
            formName="to"
            small
            value={workEventFormState.endTime}
            min={dayjs(workEventFormState.startTime, 'h:mm')
              .add(1, 'minutes')
              .format('HH:mm')}
          />
        </div>
      </div>

      {canEditAllCalendars && isUpdate && (
        <ComboboxInput<User>
          label={t('userLabel')}
          placeholder={t('searchForUser')}
          onChange={(user) => setFields({ userId: user.id })}
          defaultValue={workEventFormState.userId}
          required
          fetchUrl={(query) =>
            HttpEndpoints.UserEndpoints.getUsersForOrganization(
              organizationId,
              { query },
            )
          }
          bindLabel={(user) => formatName(user)}
          bindValue={(user) => user.id}
        />
      )}

      {!workEventFormState.addNewStudent && (
        <ComboboxInput<Student>
          label={t('studentLabel')}
          disabled={!!workEventFormState.studentDisabled}
          placeholder={t('searchForStudent')}
          onChange={(s) => {
            setFields({ studentId: s?.id });
          }}
          defaultValue={workEventFormState.studentId}
          required={
            !workEventFormState.addNewStudent &&
            workEventFormState.billingType?.licenseCategory !==
              LicenseCategory.None
          }
          queryMinLength={1}
          fetchUrl={(query) =>
            HttpEndpoints.StudentEndpoints.getStudentsForOrganization(
              organizationId,
              {
                query,
                includeBalance: false,
                archived: null,
                queryNameOnly: true,
              },
            )
          }
          bindLabel={(s) => formatName(s)}
          labelContent={(user) => {
            if (user?.archived) {
              return (
                <Tag
                  color={Color.Amber}
                  className="text-xs px-2 py-0.5 m-0 mr-0"
                >
                  <ArchiveBoxIcon className={indicatorIconClassNames} />
                  {t('studentArchived')}
                </Tag>
              );
            }
          }}
          bindValue={(s) => s.id}
        />
      )}
      {(canCreateAllStudents || canCreateOwnStudent) && (
        <BasicCheckboxInput
          label={t('addNewStudent')}
          small
          disabled={!!workEventFormState.studentDisabled}
          defaultChecked={workEventFormState.addNewStudent}
          onChange={(e) => setFields({ addNewStudent: e.target.checked })}
        />
      )}
      {workEventFormState.addNewStudent && (
        <>
          <StudentFormFields
            canSelectInstructor={canAssignInstructor}
            small
            defaultValues={workEventFormState.newStudent}
            onGroupsChanged={(studentGroups) => setFields({ studentGroups })}
          />
          <hr />
        </>
      )}

      <BasicSelectInput
        options={licenseCategories}
        small
        bindValue={(l) => l}
        value={workEventFormState.licenseCategory ?? ''}
        onChange={(e) => setFields({ licenseCategory: e.target.value })}
        bindLabel={translateLicenseCategory}
        formName="licenseCategory"
        label={t('licenseCategoryLabel')}
      />

      <ComboboxInput<BillingType>
        label={t('billingTypeLabel')}
        placeholder={t('searchForBillingType')}
        required
        defaultValue={workEventFormState.billingType?.id}
        bindValue={(b: BillingType) => b.id}
        bindLabel={(b: BillingType) => b.title}
        onChange={selectBillingType}
        fetchUrl={(query) =>
          workEventFormState.date &&
          HttpEndpoints.BillingTypeEndpoints.getBillingTypesByOrganization(
            organizationId,
            {
              query,
              activeOn: workEventFormState.date,
              licenseCategory: workEventFormState.licenseCategory || undefined,
            },
          )
        }
      />

      {(vehicles && vehicles.length > 0) || !workEventFormState.billingType ? (
        <>
          <BasicSelectInput
            options={vehicles}
            label={t('vehicleLabel')}
            formName="vehicle"
            small
            disabled={!workEventFormState.billingType}
            required={vehicleRequired}
            value={workEventFormState.primaryVehicleId ?? ''}
            bindValue={(v: Vehicle) => v.id}
            bindLabel={(v: Vehicle) => v.title || v.license_plate}
            onChange={(e) => setFields({ primaryVehicleId: e.target.value })}
          />
          <BasicSelectInput
            options={vehicles.filter(
              (v) => v.id !== workEventFormState.primaryVehicleId,
            )}
            label={t('secondaryVehicleLabel')}
            formName="secondaryVehicle"
            small
            disabled={!workEventFormState.billingType}
            value={workEventFormState.secondaryVehicleId ?? ''}
            bindValue={(v: Vehicle) => v.id}
            bindLabel={(v: Vehicle) => v.title || v.license_plate}
            onChange={(e) => setFields({ secondaryVehicleId: e.target.value })}
          />
        </>
      ) : (
        <div className="sm:space-y-2">
          <label
            htmlFor="instructor"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            {t('vehicleLabel')} {vehicleRequired && '*'}
          </label>

          <ErrorText>{t('noVehiclesAvailable')}</ErrorText>
        </div>
      )}
      {workEventFormState.billingType?.licenseCategory !==
        LicenseCategory.None && (
        <>
          <MeetingLocationInput
            specifyAddress={workEventFormState.specifyMeetingAddress}
            points={meetingPoints}
            point={workEventFormState.meetingPoint}
            onPointChange={(meetingPoint) => setFields({ meetingPoint })}
            address={workEventFormState.meetingAddress}
            onAddressChange={(meetingAddress) => setFields({ meetingAddress })}
          />
          <BasicCheckboxInput
            label={t('specifyAddress')}
            small
            defaultChecked={workEventFormState.specifyMeetingAddress}
            onChange={(e) =>
              setFields({ specifyMeetingAddress: e.target.checked })
            }
          />
        </>
      )}
    </>
  );
};
