export function formatCurrency(amount?: number | null): string | null {
  if (amount == null) return null;
  return `CHF ${formatNumber(amount, 2)}`;
}

export function formatNumber(
  n?: number | null,
  digits?: number,
  omitTrailingZeroes?: boolean,
): string | null {
  if (n == null) return null;
  return n.toLocaleString(undefined, {
    // maximumFractionDigits must be between 0 and 20 and is set to 3 by default, but if no digits are specified, we want to display as many digits as possible
    maximumFractionDigits: digits ?? 20,
    minimumFractionDigits: omitTrailingZeroes ? 0 : digits,
  });
}
