import { FullAddress } from '../typings/backend-types';
import containsAllNull from './containsAllNull';

export function formatAddress(a?: Partial<FullAddress>): string | null {
  if (!a) return null;
  if (containsAllNull(a)) return null;

  const address = [
    a.addressee,
    `${a.street?.trim?.() ?? ''} ${a.number?.trim?.() ?? ''}`,
    `${a.zip?.trim?.() ?? ''} ${a.city?.trim?.() ?? ''}`,
    a.country,
  ];

  const formattedAddress = address
    .map((item) => item?.trim())
    .filter(Boolean)
    .join(', ');

  return formattedAddress || null;
}
