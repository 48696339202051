import { FormEvent, FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';
import { max } from 'lodash';
import {
  ExamAttempt,
  UpdateExamAttemptDto,
  WorkEvent,
} from '../../../../../typings/backend-types';
import { EventDescriptionItem } from '../EventDescriptionItem';
import { BasicTextInput } from '../../../../Forms/FormFields/BasicTextInput';
import {
  ExamStatus,
  examAttemptRadioRenderer,
  examAttemptStatusOptions,
} from '../../../../Exams/ExamHelpers';
import { RadioList } from '../../../../Forms/FormFields/RadioList';
import { Form } from '../../../../Forms/Form';
import authenticatedPost from '../../../../../data/authenticatedPost';
import { HttpEndpoints } from '../../../../../data/httpEndpoints';
import { useErrorPopupContext } from '../../../../../context/errorPopupContext';
import authenticatedFetcher from '../../../../../data/authenticatedFetcher';
import { ExaminerInput } from '../../../../Exams/ExaminerInput';
import { suggestArchivingPassedStudent } from '../../../../../utilities/students/suggestArchivingPassedStudent';
import { useConfirmPopupContext } from '../../../../../context/confirmPopupContext';
import { useNotificationContext } from '../../../../../context/notificationContext';
import { useAppContext } from '../../../../../context/appContext';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../../../typings/roleConfig';
import { translateLicenseCategory } from '../../../../../utilities/translation/translate-license-category';
import { WorkEventPopupTab } from './WorkEventViewPopup';

interface FormElements {
  status: HTMLInputElement;
  comment: HTMLInputElement;
}

interface Props {
  event: WorkEvent;
  onCloseForm: () => void;
  changeTab: (tab: WorkEventPopupTab) => void;
}

export const ExamAttemptEdit: FunctionComponent<Props> = ({
  event,
  onCloseForm,
  changeTab,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendarPopups.viewWorkEvent.examAttemptInfo',
  });
  const { setErrorMessage } = useErrorPopupContext();
  const { setNotification } = useNotificationContext();
  const { confirm } = useConfirmPopupContext();
  const { organizationId } = useAppContext();

  const [examiner, setExaminer] = useState(event.examAttempt.examiner);

  const { data: examAttempts } = useSWR<ExamAttempt[]>(
    HttpEndpoints.ExamEndpoints.getExamAttemptsByExam(
      event.examAttempt.exam.id,
      event.organization.id,
    ),
    authenticatedFetcher,
    { onError: () => setErrorMessage(t('errorFetchAttempts')) },
  );

  const hasDeleteExamPermission = useUserPermission(
    Permission.STUDENT_EXAMS,
    Scope.READ_WRITE_DELETE,
  );

  const canDeleteAttempt = useMemo(() => {
    if (!examAttempts) return false;
    if (!hasDeleteExamPermission) return false;
    return (
      event.examAttempt.attempt_number ===
      max(examAttempts.map((attempt) => attempt.attempt_number))
    );
  }, [examAttempts, event.examAttempt.attempt_number, hasDeleteExamPermission]);

  async function submit(e: FormEvent<FormElements>): Promise<void> {
    const passed = e.currentTarget.status.value === ExamStatus.PASSED;
    const examAttemptDto: UpdateExamAttemptDto = {
      passed,
      examiner,
      comment: e.currentTarget.comment.value,
    };

    await authenticatedPost(
      HttpEndpoints.ExamAttemptEndpoints.patchExamAttempt(
        event.examAttempt.id,
        event.organization.id,
      ),
      examAttemptDto,
      'PATCH',
    );

    if (passed) {
      void suggestArchivingPassedStudent(
        event.student,
        organizationId,
        confirm,
        setErrorMessage,
        setNotification,
      );
    }

    await mutate(
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        event.id,
        event.user?.id,
      ),
    );
    onCloseForm();
  }

  async function deleteAttempt() {
    if (!canDeleteAttempt) return;
    try {
      await authenticatedFetcher(
        HttpEndpoints.ExamAttemptEndpoints.deleteExamAttempt(
          event.examAttempt.id,
          event.organization.id,
        ),
        'DELETE',
      );
    } catch (e) {
      setErrorMessage(t('errorDeleteExamAttempt'));
    }
    void mutate(
      HttpEndpoints.WorkEventEndpoints.getWorkEventById(
        event.id,
        event.user?.id,
      ),
    );
    void mutate(
      HttpEndpoints.ExamEndpoints.getExamAttemptsByExam(
        event.examAttempt.exam.id,
        organizationId,
      ),
    );
    onCloseForm();
    changeTab(WorkEventPopupTab.eventDetails);
  }

  const canEditExams = useUserPermission(
    Permission.STUDENT_EXAMS,
    Scope.READ_WRITE,
  );

  if (!canEditExams) return null;

  return (
    <Form
      onSubmit={submit}
      onDelete={canDeleteAttempt && deleteAttempt}
      deletePermission={Permission.STUDENT_EXAMS}
      deleteConfirmationText={t('deleteConfirmationText')}
      onCancel={onCloseForm}
      onSubmitError={() => setErrorMessage(t('errorUpdateExamAttempt'))}
    >
      <div className="divide-y">
        <div className="grid grid-cols-2 gap-4">
          <EventDescriptionItem label={t('exam')}>
            {translateLicenseCategory(event.examAttempt.exam.licenseCategory)}
          </EventDescriptionItem>
          <EventDescriptionItem label={t('attemptNumber')}>
            {event.examAttempt.attempt_number}
          </EventDescriptionItem>
        </div>
        <ExaminerInput
          onChange={setExaminer}
          className="pt-3"
          defaultValue={event.examAttempt.examiner}
        />
        <BasicTextInput
          label={t('comment')}
          formName="comment"
          type="text"
          small
          className="py-3"
          value={event.examAttempt.comment}
        />
        <RadioList
          formName="status"
          label={t('status')}
          options={examAttemptStatusOptions}
          defaultCheckedId={
            event.examAttempt.passed ? ExamStatus.PASSED : ExamStatus.FAILED
          }
          className="pt-3"
          renderOption={examAttemptRadioRenderer}
          required
          small
          inlineOptions
        />
      </div>
    </Form>
  );
};
