import FullCalendar from '@fullcalendar/react';

export enum CalendarView {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  THREE_DAYS = 'three-days',
}

export function getCalendarView(
  calendarRef: React.RefObject<FullCalendar>,
): CalendarView {
  if (matchViewType(calendarRef, 'timeGridDay')) return CalendarView.DAY;
  if (matchViewType(calendarRef, 'timeGridWeek')) return CalendarView.WEEK;
  if (matchViewType(calendarRef, 'dayGridMonth')) return CalendarView.MONTH;
  if (matchViewType(calendarRef, 'timeGridThreeDays')) {
    return CalendarView.THREE_DAYS;
  }
}

export function viewToViewType(view: CalendarView): string {
  switch (view) {
    case CalendarView.DAY:
      return 'timeGridDay';
    case CalendarView.WEEK:
      return 'timeGridWeek';
    case CalendarView.MONTH:
      return 'dayGridMonth';
    case CalendarView.THREE_DAYS:
      return 'timeGridThreeDays';
  }
}

function matchViewType(
  calendarRef: React.RefObject<FullCalendar>,
  view: string,
): boolean {
  return calendarRef?.current?.getApi?.()?.view?.type === view ?? false;
}
