import React, { FunctionComponent } from 'react';
import { CalendarSelection, EventType } from '../Common/CalendarSelection';

import { VacationEventCreateUpdatePopup } from './VacationEventCreateUpdatePopup';
import { WorkEventCreateUpdatePopup } from './WorkEventCreateUpdatePopup';

export interface EventCreateUpdatePopupProps {
  calendarSelection: CalendarSelection;
  position: [number, number];
  onClose: () => void;
  setIsLoading: (loading: boolean) => void;
  onCreateOrUpdate: () => void;
}

export const EventCreateUpdatePopup: FunctionComponent<
  EventCreateUpdatePopupProps
> = ({
  calendarSelection,
  position,
  onClose,
  onCreateOrUpdate,
  setIsLoading,
}: EventCreateUpdatePopupProps) => {
  if (!calendarSelection) return null;

  switch (calendarSelection.eventType) {
    case EventType.WORK:
      return (
        <WorkEventCreateUpdatePopup
          calendarSelection={calendarSelection}
          position={position}
          onClose={onClose}
          setIsLoading={setIsLoading}
          onCreateOrUpdate={onCreateOrUpdate}
        />
      );
    case EventType.VACATION:
      return (
        <VacationEventCreateUpdatePopup
          calendarSelection={calendarSelection}
          position={position}
          onClose={onClose}
          setIsLoading={setIsLoading}
          onCreateOrUpdate={onCreateOrUpdate}
        />
      );
    case EventType.ICAL:
    // The user can't edit ICalEvents, so we don't need to show a popup for those.
    default:
      return null;
  }
};
