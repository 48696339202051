import React, { FunctionComponent } from 'react';

import { CalendarSelection, EventType } from '../Common/CalendarSelection';
import { IcalEventViewPopup } from './IcalEventViewPopup';
import { WorkEventViewPopup } from './WorkEventViewPopup/WorkEventViewPopup';
import { VacationEventViewPopup } from './VacationEventViewPopup';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

interface Props {
  calendarSelection: CalendarSelection;
  popupPosition: [number, number];
  onClose: () => void;
  onEdit: () => void;
  onCopy: () => void;
}

export const EventDetailsPopup: FunctionComponent<Props> = ({
  calendarSelection,
  popupPosition,
  onClose,
  onEdit,
  onCopy,
}) => {
  if (!calendarSelection) return null;

  switch (calendarSelection.eventType) {
    case EventType.WORK:
      return (
        <WorkEventViewPopup
          position={popupPosition}
          isOpen={!!calendarSelection && !!popupPosition}
          onClose={onClose}
          onEdit={onEdit}
          onCopy={onCopy}
          calendarSelection={calendarSelection}
        />
      );
    case EventType.ICAL:
      return (
        <IcalEventViewPopup
          position={popupPosition}
          isOpen={!!calendarSelection && !!popupPosition}
          onClose={onClose}
          calendarSelection={calendarSelection}
        />
      );
    case EventType.VACATION:
      return (
        <VacationEventViewPopup
          position={popupPosition}
          isOpen={!!calendarSelection && !!popupPosition}
          onClose={onClose}
          onEdit={onEdit}
          calendarSelection={calendarSelection}
        />
      );
    default:
      return null;
  }
};
