import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utilities/classNames';

interface Props<T> {
  options: T[];
  bindValue: (option: T) => string | number;
  bindLabel: (option: T) => string | JSX.Element;
  onChange?: (option: T) => void;
  selected?: T;
  // Used for apeending elements such as icons on the right side of each selectable item
  rightSide?: (item: T) => JSX.Element;
  checkOptionsEqual?: (a: T, b: T) => boolean;
}

/**
 * Select component with custom option list from https://tailwindui.com/components/application-ui/forms/select-menus#component-8298198b136afab3bb19391ae716077f
 */
export function CustomSelectInput<T>({
  options,
  bindValue,
  bindLabel,
  onChange,
  selected,
  rightSide,
  checkOptionsEqual,
}: Props<T>): JSX.Element {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.customSelectInput',
  });
  return (
    <Listbox
      value={selected}
      by={checkOptionsEqual}
      onChange={(e) => {
        onChange?.(e);
      }}
    >
      <div className="relative">
        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-11 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-accent-500 focus:border-accent-500 text-sm">
          <div className={classNames('truncate', !selected && 'text-gray-500')}>
            {(selected && bindLabel(selected)) ?? t('selectAnItem')}
          </div>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={bindValue(option)}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-accent-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-1 group',
                  )
                }
                value={option}
              >
                {({ selected, active }) => (
                  <div className="flex">
                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-accent-600',
                          'flex items-center pr-1 -ml-6',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}

                    <div
                      className={classNames(
                        selected ? 'font-semibold' : 'font-normal',
                        'truncate flex-grow',
                      )}
                    >
                      {bindLabel(option)}
                    </div>

                    {rightSide ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-gray-500',
                          'shrink-0 flex items-center pr-2 pl-1',
                        )}
                      >
                        {rightSide(option)}
                      </span>
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
