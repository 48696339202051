import router from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';
import { CalendarSelection } from './EventPopups/Common/CalendarSelection';
import { WorkEventCopyPopup } from './EventPopups/CopyPopup/WorkEventCopyPopup';
import { EventCreateUpdatePopup } from './EventPopups/CreateUpdatePopups/EventCreateUpdatePopup';
import { EventDetailsPopup } from './EventPopups/ViewPopups/EventDetailsPopup';

interface Props {
  popupPosition: [number, number];
  calendarSelection: CalendarSelection;
  setIsLoading: (isLoading: boolean) => void;
  onEventsChanged: () => void;
  onPopupClose: () => void;
}

// Available states for the state machine:
enum CalendarPopupState {
  CLOSED,
  VIEW,
  COPY,
  CREATE_UPDATE,
}

export const CalendarPopups: FunctionComponent<Props> = ({
  calendarSelection,
  popupPosition,
  setIsLoading,
  onEventsChanged,
  onPopupClose,
}: Props) => {
  const [viewState, setViewState] = useState<CalendarPopupState>(
    CalendarPopupState.CLOSED,
  );

  useEffect(() => {
    if (!calendarSelection) {
      setViewState(CalendarPopupState.CLOSED);
      void router.replace(
        { query: { ...router.query, event: '' } },
        undefined,
        {
          shallow: true,
        },
      );
      return;
    }
    if (!!calendarSelection.eventId) {
      setViewState((oldState) =>
        oldState === CalendarPopupState.CLOSED
          ? CalendarPopupState.VIEW
          : CalendarPopupState.CLOSED,
      );
      void router.replace(
        { query: { ...router.query, event: calendarSelection.eventId } },
        undefined,
        {
          shallow: true,
        },
      );
      return;
    }
    if (!calendarSelection.eventId) {
      setViewState((oldState) =>
        oldState === CalendarPopupState.CLOSED
          ? CalendarPopupState.CREATE_UPDATE
          : CalendarPopupState.CLOSED,
      );
      return;
    }
  }, [calendarSelection]);

  function closePopup() {
    setViewState(CalendarPopupState.CLOSED);
    onPopupClose();
  }

  switch (viewState) {
    case CalendarPopupState.VIEW:
      return (
        <EventDetailsPopup
          popupPosition={popupPosition}
          onClose={closePopup}
          onCopy={() => {
            setViewState(CalendarPopupState.COPY);
          }}
          onEdit={() => setViewState(CalendarPopupState.CREATE_UPDATE)}
          calendarSelection={calendarSelection}
        />
      );
    case CalendarPopupState.CREATE_UPDATE:
      return (
        <EventCreateUpdatePopup
          calendarSelection={calendarSelection}
          position={popupPosition}
          onClose={closePopup}
          setIsLoading={setIsLoading}
          onCreateOrUpdate={onEventsChanged}
        />
      );
    case CalendarPopupState.COPY:
      return (
        <WorkEventCopyPopup
          position={popupPosition}
          onClose={closePopup}
          calendarSelection={calendarSelection}
          reloadEvents={onEventsChanged}
        />
      );
    case CalendarPopupState.CLOSED:
    default:
      return null;
  }
};
