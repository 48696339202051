import { Color } from '../typings/backend-types';

export function getTextAndBackgroundByNumber(n: number): string {
  if (n > 0) return 'text-green-600 bg-green-100';
  if (n < 0) return 'text-red-600 bg-red-100';
  return 'bg-gray-100 text-gray-900';
}

export function getTextColor(n: number): string {
  if (n > 0) return 'text-green-600';
  if (n < 0) return 'text-red-600';
  return '';
}

export function getColor(n: number): Color | undefined {
  if (n > 0) return Color.Green;
  if (n < 0) return Color.Red;
  return undefined;
}
