import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React, { FunctionComponent } from 'react';

/**
 * Error text with a red exclamation mark
 */
export const ErrorText: FunctionComponent = ({ children }) => {
  return (
    <p className="text-xs font-normal text-gray-700 inline-flex items-center">
      <ExclamationCircleIcon className="w-5 h-5 text-red-600 inline mr-1 align-text-bottom" />
      <span>{children}</span>
    </p>
  );
};
