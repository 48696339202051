import React, { FunctionComponent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { BasicTextInput, dateInputFormat } from './BasicTextInput';
import { classNames } from 'utilities/classNames';

interface Props {
  defaultStart: Dayjs;
  defaultEnd: Dayjs;
  onStartChange: (start: Dayjs) => void;
  onEndChange: (end: Dayjs) => void;
  maxStart?: Dayjs;
  minStart?: Dayjs;
  minEnd?: Dayjs;
  className?: string;
}

/**
 * two date inputs with the labels From and To
 */
export const DateRangeInputs: FunctionComponent<Props> = ({
  defaultStart: startDate,
  defaultEnd: endDate,
  onStartChange,
  onEndChange,
  maxStart,
  minStart,
  minEnd,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.dateRangeInput',
  });

  return (
    <div className={classNames('grid grid-cols-2 gap-2', className)}>
      <BasicTextInput
        small
        type="date"
        formName="from"
        label={t('fromLabel')}
        value={dayjs(startDate).format(dateInputFormat)}
        onChange={(e) => {
          onStartChange(dayjs(e.target.value));
        }}
        max={maxStart ? dayjs(maxStart).format(dateInputFormat) : undefined}
        min={minStart ? dayjs(minStart).format(dateInputFormat) : undefined}
      />
      <BasicTextInput
        small
        type="date"
        formName="to"
        label={t('toLabel')}
        value={dayjs(endDate).format(dateInputFormat)}
        onChange={(e) => onEndChange(dayjs(e.target.value))}
        min={minEnd ? dayjs(minEnd).format(dateInputFormat) : undefined}
      />
    </div>
  );
};
